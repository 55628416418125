import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Provider } from '@/components/Developers/Provider';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import DemoForm from '@/components/DemoForm';
import BespokeSolutionsIcon from '@/assets/developers/bespoke-solutions.webp';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Provider mdxType="Provider">
      <Box textAlign="center" paddingY={3} mdxType="Box">
        <h4 {...{
          "id": "have-specific-requirements-or-a-unique-use-case",
          "style": {
            "position": "relative"
          }
        }}>{`Have specific requirements or a unique use case?`}<a parentName="h4" {...{
            "href": "#have-specific-requirements-or-a-unique-use-case",
            "aria-label": "have specific requirements or a unique use case permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box textAlign="left" mdxType="Box">
        <p>{`TaleFin doesn't just provide a service, we partner with vendors to develop solutions specific to their industries and workflows. This creates
efficient processes with great customer experiences while reducing application drop off rates and maximising traffic value.`}</p>
        <p>{`With our extensive range of metrics, we probably have one that suits every possible use case you may encounter. If you have a requirement for
something unique, we can develop custom metrics, workflows and even provide customised analysis templates that are branded to your brand.`}</p>
      </Box>
      <Box padding={4} mdxType="Box">
        <HighlightCardWithIcon icon={BespokeSolutionsIcon} mdxType="HighlightCardWithIcon">
          <p>{`Customised options enrich your insights and give power to our clients to compare a consumer across key affordability, reliability and social behaviour indicators.`}</p>
          <p>{`Our proprietary bank statement solution utilises our knowledge of financial services to provide accurate and trusted insights. This is derived by using financial data obtained from all customer bank accounts.`}</p>
          <p>{`If you need advanced solutions, we can also support customised scoring utilising benchmarked data. Using a set of customisable analytical solutions for Enterprise-level clients that require the most advanced capabilities.`}</p>
        </HighlightCardWithIcon>
      </Box>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      